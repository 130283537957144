/* Customize modal header */
.modal-header {
    background-color: #007bff; /* Blue header background */
    color: #fff; /* White text color */
  }
  
  /* Customize modal title */
  .modal-title {
    font-weight: bold; /* Bold title text */
  }
  
  /* Customize modal body */
  .modal-body {
    padding: 20px; /* Add padding to body */
  }
  
  /* Customize modal footer */
  .modal-footer {
    justify-content: center; /* Center align footer buttons */
    border-top: none; /* Remove top border */
  }
  
  /* Style close button */
  .close {
    color: #fff; /* White close button text color */
    opacity: 1; /* Full opacity */
    font-size: 1.5rem; /* Larger font size */
  }
  
  .close:hover {
    color: #f8f9fa; /* Lighter close button text color on hover */
    opacity: 1; /* Maintain full opacity on hover */
  }
  .justify-text {
    text-align: justify;
  }
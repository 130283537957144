/* FormDataPreview.css */

.form-data-preview {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .form-data-preview h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .form-data-preview ul {
    list-style-type: none;
    padding: 0;
  }
  
  .form-data-preview li {
    margin-bottom: 10px;
  }
  
  .form-data-preview strong {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .even-row {
    background-color: #f2f2f2; /* Light gray background for even rows */
  }
  
  .odd-row {
    background-color: #ffffff; /* White background for odd rows */
  }  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-justify {
  text-align: justify!important;
  position: relative!important;
  font-family: var(--secondary-font)!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 29px!important;
  color: var(--base-color)!important;
  margin-bottom: 0px!important;
  transition: all 500ms ease!important;
}

.Fabtn{
  color:white;
  font-family: 'Poppins'
}
.Fabtn:hover{
  color:#F65024
}

.AccStyle{
  color:red
}

.modal-btn{
  color:white;
  font-family: 'Poppins'
}

.modal-btn:hover{
  color:#F65024;
  cursor: pointer;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
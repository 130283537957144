/* ErrorBoundary.css */
.error-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8d7da;
    color: #721c24;
    font-family: Arial, sans-serif;
  }
  
  .error-boundary-content {
    text-align: center;
    padding: 20px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    background-color: #f8d7da;
  }
  
  .error-boundary h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .error-boundary p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .error-boundary-button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #c82333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .error-boundary-button:hover {
    background-color: #a71d2a;
  }
  
/* Form.css */

.user-info {
  /* background-color: #f8f9fa; */
  padding: 50px 0;
}

.user-info .container {
  max-width: 900px;
  margin: 0 auto;
}

.user-info .align-title {
  text-align: center;
}

.user-info h5 {
  /* color: #333; */
  /* font-size: 1.2rem; */
  /* margin-bottom: 10px; */
}

.user-info h3 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 30px;
}

.user-info form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.user-info input {
  width: 100%;
  padding: 13px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}
.user-info textarea {
  width: 100%;
  padding: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  min-height: 49px;
}
.user-info select {
  width: 100%;
  padding: 11px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.user-info input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
.terms-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
}

.terms-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.terms-checkbox input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #007bff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.terms-checkbox input:checked ~ .checkmark {
  background-color: #007bff;
}

.terms-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.terms-list {
  padding: 0;
  list-style-type: none;
}

.terms-list li {
  margin-bottom: 10px;
  font-size: 16px;
  counter-increment: term;
}

.terms-list li:before {
  content: counter(term) ".";
  margin-right: 10px;
  font-weight: bold;
}

/* Add hover effect */
.terms-list li:hover {
  color: #007bff;
}

/* Add animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  from {
    opacity: 0;
    transform: translateY(10px); /* Start slightly below and fade in */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.terms-list li {
  animation: fadeIn 0.5s ease-in-out;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-info button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s ease;
}

.user-info button:hover {
  background-color: #0056b3;
}

/* .pattern-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #2980b9, #6dd5fa, #ffffff);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
} */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.pattern-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../public/assets/images/shape/home-two-about-shape.png");
  /* background-size: cover; */
  animation: animateBackground 10s linear infinite alternate;
  opacity: 0.7;
}

@keyframes animateBackground {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1.1);
    opacity: 0.7;
  }
}

select {
  background: rgba(0, 0, 0, 0);
}
input[type="text"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="date"]::placeholder {
  color: black; /* Default color */
  opacity: 1; /* Adjust opacity for faded effect */
}
.validation-error {
  border-color: red;
}
label.error {
  color: red !important;
  font-size: 12px !important;
  margin-top: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 15px !important;
}

input.error {
  border: 1px solid red !important;
}
select.error {
  border: 1px solid red !important;
}
.required {
  color : red !important;
}